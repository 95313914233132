<template>
  <div>
    <a-result status="500" title="500" sub-title="Oops, Sanırım sunucuda bir sorun meydana geldi.">
      <template #extra>
        <router-link to="/" class="btn btn-outline-primary width-100">
          Geri Dön
        </router-link>
      </template>
    </a-result>
  </div>
</template>
<script>
export default {
  components: {
  },
}
</script>
